import request from "@/utils/request";

//查询客户信息
export function getCustomerList(data) {
  return request({
    url: "api/v2/m_crm/selectCustomerList",
    method: "post",
    data,
  });
}

//新增客户
export function addCustomer(data) {
  return request({
    url: "api/v2/m_crm/saveCustomer",
    method: "post",
    data,
  });
}
//修改客户
export function updateCustomer(data) {
  return request({
    url: "api/v2/m_crm/saveUpdateCustomer",
    method: "post",
    data,
  });
}
//删除客户
export function delCustomer(data) {
  return request({
    url: "api/v2/m_crm/delCustomer",
    method: "post",
    data,
  });
}
//批量删除客户信息
export function batchDelCustomer(data) {
  return request({
    url: "api/v2/m_crm/batchDelCustomer",
    method: "post",
    data,
  });
}
//记录信息
export function getRecodeList(data) {
  return request({
    url: "api/v2/m_crm/selectRecordList",
    method: "post",
    data,
  });
}
//新增记录
export function addRecode(data) {
  return request({
    url: "api/v2/m_crm/saveRecord",
    method: "post",
    data,
  });
}
//删除记录
export function delRecode(data) {
  return request({
    url: "api/v2/m_crm/delRecord",
    method: "post",
    data,
  });
}
//跟进状态列表
export function getStateList(data) {
  return request({
    url: "api/v2/m_crm/customerStateList",
    method: "post",
    data,
  });
}
//新增跟进状态
export function addState(data) {
  return request({
    url: "api/v2/m_crm/saveCustomerState",
    method: "post",
    data,
  });
}
//批量修改状态
export function saveStatus(data) {
  return request({
    url: "api/v2/m_crm/saveTotalSaves",
    method: "post",
    data,
  });
}
//查询业务类型
export function getCategoryList(data) {
  return request({
    url: "api/v2/m_crm/selectCategoryList",
    method: "post",
    data,
  });
}
//新增业务类型
export function addCategory(data) {
  return request({
    url: "api/v2/m_crm/saveCategory",
    method: "post",
    data,
  });
}
//新增业务类型名称
export function addCategoryName(data) {
  return request({
    url: "api/v2/m_crm/saveCategoryName",
    method: "post",
    data,
  });
}
//删除业务类型
export function delCategory(data) {
  return request({
    url: "api/v2/m_crm/delCategory",
    method: "post",
    data,
  });
}
//删除业务类型名称
export function delCategoryName(data) {
  return request({
    url: "api/v2/m_crm/delCategoryName",
    method: "post",
    data,
  });
}
//查询合同
export function getContractList(data) {
  return request({
    url: "api/v2/m_crm/selectContractList",
    method: "post",
    data,
  });
}

//新增合同
export function addContract(data) {
  return request({
    url: "api/v2/m_crm/saveContract",
    method: "post",
    data,
  });
}
//修改合同
export function updateContract(data) {
  return request({
    url: "api/v2/m_crm/saveUpdateContract",
    method: "post",
    data,
  });
}
//单独删除合同
export function delContract(data) {
  return request({
    url: "api/v2/m_crm/delContract",
    method: "post",
    data,
  });
}
//批量删除合同
export function delAllContract(data) {
  return request({
    url: "api/v2/m_crm/batchDelContract",
    method: "post",
    data,
  });
}
//批量分配合同跟进人修改合同状态
export function saveContractStatus(data) {
  return request({
    url: "api/v2/m_crm/saveTotalContract",
    method: "post",
    data,
  });
}
//续约合同
export function renewalContract(data) {
  return request({
    url: "api/v2/m_crm/renewalContract",
    method: "post",
    data,
  });
}

//首页echarts
export function homeList(data) {
  return request({
    url: "api/v2/m_crm/homeList",
    method: "post",
    data,
  });
}

//导入合同
export function importContract(data) {
  return request({
    url: "api/v2/m_crm/contractImport",
    method: "post",
    data,
  });
}
//导出合同
export function exportContract(data) {
  return request({
    url: "api/v2/m_crm/exportContract",
    method: "post",
    data,
  });
}
//导入合作客户
export function importCustomer(data) {
  return request({
    url: "api/v2/m_crm/customerImport",
    method: "post",
    data,
  });
}
//导入跟进客户
export function importFollowCustomer(data) {
  return request({
    url: "api/v2/m_crm/followCustomerImport",
    method: "post",
    data,
  });
}

//导出档案
export function exportRecord(data) {
  return request({
    url: "api/v2/m_crm/exportRecord",
    method: "post",
    data,
  });
}
//商品列表
export function productsList(data) {
  return request({
    url: "api/v2/wz/productsList",
    method: "post",
    data,
  });
}

//保存商标
export function saveTrademark(data) {
  return request({
    url: "api/v2/wz/saveTrademark",
    method: "post",
    data,
  });
}

// 商标审核列表
export function trademarkList(data) {
  return request({
    url: "api/v2/wz/trademarkList",
    method: "post",
    data,
  });
}

// 商标审核

export function trademarkExamine(data) {
  return request({
    url: "api/v2/wz/trademarkExamine",
    method: "post",
    data,
  });
}